import { useEffect, useState } from 'react';
import { setConversationData, getConversationData } from '../services/conversation';

export const Screen = ({ updateTelefone, telefone, eventSocket }) => {
    const [attaches, setAttaches] = useState(false);

    const handleInputChange = (event) => {
        updateTelefone(event.target.value);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                console.log('### screen Texto copiado para a área de transferência!');
            })
            .catch((err) => {
                console.error('### screen Erro ao copiar o texto: ', err);
            });
    };

    const getDataAPI = async () => {
        console.log('### screen getDataAPI')
        await setConversationData();
        const result = await getConversationData();
        const newResult = result.dt_res1000_dados.split(';')
            .filter(item => item)
            .reduce((acc, item) => {
                const [key, value] = item.split('=');
                acc[key] = value;
                return acc;
            }, {});

        return newResult;
    }

    const getAtributes = async (event) => {
        for (let i = 0; i < event.length; i++) {
            console.log('### screen event getAtributes ', event)
            var atributes = event[i].attributes;
            console.log('### screen (i === event.length -1) && (!atributes.dt_nav_relatorio) ', (i === event.length - 1) && (!atributes.dt_nav_relatorio))

            // pega os valores do websocket
            if (atributes.dt_nav_relatorio) {
                return atributes;
            }

            // se não tiver pega da API
            if ((i === event.length - 1) && (!atributes.dt_nav_relatorio)) {
                await getDataAPI();
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            var data = {}
            if (eventSocket) {
                console.log('### screen eventSocket ', eventSocket)
                data = await getAtributes(eventSocket);
                setAttaches(data);
                console.log('### screen data getAtributes', data);
            }

            if (eventSocket === undefined || eventSocket === false) {
                console.log('### screen eventSocket is not valid');
                data = await getDataAPI();
                setAttaches(data);
                console.log('### screen data getDataAPI', data)
            }


            console.log('### screen eventSocket atualizado', eventSocket)
            updateTelefone(data["dt_call_ani_retorno"] ? data["dt_call_ani_retorno"] : data["ani_retorno"]);
        };
        fetchData()
            .catch((e) => {
                console.error(`### screen Catch fetchData: ${e.message}`)
            })
    }, [eventSocket])

    useEffect(() => {
        if (attaches) {

            // efeito de ler mais.
            const longText = attaches["dt_nav_relatorio"] ? attaches["dt_nav_relatorio"] : attaches["navegacao"];
            const textSplit = longText.split('|');
            const shortText = textSplit.slice(0, 3).join('|');

            console.log('### screen shortText ', shortText);
            console.log('### screen longText ', longText);

            const spanElement = document.getElementById('mySpan');
            if (spanElement) {
                spanElement.textContent = shortText + ' ...';
                spanElement.title = 'Para copiar click na caixa azul: ' + longText;
            }

            // traduzindo attache boleano.
            const inadimplente = attaches["dt_call_inadimplente"];
            const rechamada = attaches["dt_call_rechamada"] ? attaches["dt_call_rechamada"] : attaches["rechamada"];

            if (inadimplente === false || inadimplente === undefined || inadimplente.toUpperCase() === 'FALSE' || inadimplente.toUpperCase() === 'NAO' || inadimplente.toUpperCase() === 'NÃO') {
                const inadimplenteElement = document.getElementById('inadimplente');
                inadimplenteElement.textContent = 'Não'
            } else {
                const inadimplenteElement = document.getElementById('inadimplente');
                inadimplenteElement.textContent = 'Sim'
            }


            if (rechamada === false || rechamada === undefined || rechamada.toUpperCase() === 'FALSE' || rechamada.toUpperCase() === 'NAO' || rechamada.toUpperCase() === 'NÃO') {
                const rechamadaElement = document.getElementById('rechamada');
                rechamadaElement.textContent = 'Não'
            } else {
                const rechamadaElement = document.getElementById('rechamada');
                rechamadaElement.textContent = 'Sim'
            }
        }
    }, [attaches]);

    return (
        attaches &&
        <div className="containerCard">
            <div className="card">
                <div className="headerCard">
                    <img width={200} height={55} className="m-1" src={require('../assets/neo/neologo.png')} />
                </div>
                <hr style={{ color: "#C4C4C4" }} />
                <div className="bodyCard">
                    <div className="col-6 m-0 p-0" style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', height: '100%' }}>
                        <div className="onFlex">
                            <div>
                                <p className="text">Caminho da URA: </p>
                            </div>
                            <div>
                                <span id='mySpan' onClick={() => copyToClipboard(attaches["dt_nav_relatorio"] ? attaches["dt_nav_relatorio"] : attaches["navegacao"])} className="textResult"></span>
                            </div>
                        </div>
                        <div className="onFlex">
                            <div>
                                <p className="text">Empresa: </p>
                            </div>
                            <div>
                                <p className="textResult">{attaches["dt_call_empresa"] ? attaches["dt_call_empresa"] : attaches["empresa"]}</p>
                            </div>
                        </div>
                        <div className="onFlex">
                            <div>
                                <p className="text">Protocolo: </p>
                            </div>
                            <div>
                                <p className="textResult">{attaches["dt_call_protocolo"] ? attaches["dt_call_protocolo"] : attaches["protocolo"]}</p>
                            </div>
                        </div>
                        <div className="onFlex">
                            <div>
                                <p className="text">Saleforce: </p>
                            </div>
                            <div>
                                <p className="textResult">{(attaches["dt_call_protocolo_wso2"] ? attaches["dt_call_protocolo_wso2"] : attaches["protocolo_wso2"])}</p>
                            </div>
                        </div>
                        <div className="onFlex">
                            <div>
                                <p className="text">Rechamada: </p>
                            </div>
                            <div>
                                <p id='rechamada' className="textResult">{(attaches["dt_call_rechamada"] ? attaches["dt_call_rechamada"] : attaches["rechamada"])}</p>
                            </div>
                        </div>
                        <div className="onFlex">
                            <div>
                                <p className="text">Inadimplente: </p>
                            </div>
                            <div>
                                <p id='inadimplente' className="textResult">{attaches["dt_call_inadimplente"]}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 m-0 p-0" style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', height: '100%' }}>
                        <div className="onFlex">
                            <div>
                                <p className="text">Serviço: </p>
                            </div>
                            <div>
                                <p className="textResult">{attaches["dt_call_servico"] ? attaches["dt_call_servico"] : attaches["servico"]}</p>
                            </div>
                        </div>
                        <div className="onFlex">
                            <div>
                                <p className="text">DDD: </p>
                            </div>
                            <div>
                                <p className="textResult">{attaches["dt_call_ddd"] ? attaches["dt_call_ddd"] : attaches["ddd"]}</p>
                            </div>
                        </div>
                        <div className="onFlex">
                            <div>
                                <p className="text">Telefone: </p>
                            </div>
                            <div>
                                <p className="textResult">{attaches["dt_call_ani_retorno"] ? attaches["dt_call_ani_retorno"] : attaches["ani_retorno"]}</p> {/* é o mesmo que o telefone  */}
                            </div>
                        </div>
                        <div className="onFlex">
                            <div>
                                <p className="text">Telefone Retorno: </p>
                            </div>
                            <div>
                                <input type="text" className="textResult" value={telefone} onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="onFlex">
                            <div>
                                <p className="text">UC Conta Contrato: </p>
                            </div>
                            <div>
                                <p className="textResult">{attaches["dt_call_uc"] ? attaches["dt_call_uc"] : attaches["uc"]}</p>
                            </div>
                        </div>
                        <div className="onFlex">
                            <div>
                                <p className="text">Atualização Cadastral: </p>
                            </div>
                            <div>
                                <p className="textResult">{attaches["dt_call_atualizacao_cadastral"]? "Sim": "Não"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
